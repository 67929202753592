<template>
    <div class="conatct" id="contact">
        <div class="logo">
            CYBER PARK
        </div>

        <div class="slogan">
            <i class="el-icon-phone-outline" style="margin-left:10px;"></i>
            <p>אנו חוזרים עד 24 שעות מרגע קבלת הפנייה.</p>
        </div>

        <el-input placeholder="שם פרטי" v-model="form.name"></el-input>
        <p v-if="form_error.name" class="error">{{form_error.name}}</p>

        <el-input placeholder="שם החברה" v-model="form.company"></el-input>

        <el-input type="email"  placeholder="כתובת אימייל" v-model="form.email"></el-input>
        <p v-if="form_error.email" class="error">{{form_error.email}}</p>

        <el-input type="number"  placeholder="מספר פלאפון" v-model="form.phone"></el-input>
        <p v-if="form_error.phone" class="error">{{form_error.phone}}</p>
        
        <el-input type="textarea" placeholder="תוכן ההודעה" v-model="form.text" :rows="4" resize="none"></el-input>
        <p v-if="form_error.text" class="error">{{form_error.text}}</p>

        <el-button type="success" class="option-btn" @click="check">שלח</el-button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';
import Swal from 'sweetalert2';
import {projectFirestore} from '../../firebase/config';
import router from '../../router';

export default {
setup(){
    const form = ref({
        name: '',
        company: '',
        phone: '',
        email: '',
        text: '',
        read: false,
        date: new Date()
    })
    const form_error = ref({
        name: '',
        company: '',
        phone: '',
        email: '',
        text: ''
    })

    watch(form.value, () => {
        if(form.value.name != '' && form.value.name.length < 3){form_error.value.name = "אנא מלא שם תקני"}else{form_error.value.name = ''};
        if(form.value.text != '' && form.value.text.length < 3){form_error.value.text = "אנא רשום את מטרת הפנייה בקצרה"}else{form_error.value.text = ''};
        if(form.value.phone != '' && form.value.phone.length < 10){form_error.value.phone = "אנא מלא פלאפון תקני"}else{form_error.value.phone = ''};
        if(form.value.email != '' && !form.value.email.includes('@')){form_error.value.email = "אנא מלא כתובת תקנית"}else{form_error.value.email = ''};
    })

    const check = async () => {
        new Promise((resolve) => {
            for (var key in form_error.value) {
                if (form_error.value[key] !== '')
                        resolve(false);
            }
            for (var key in form.value) {
                if (form.value[key] == '')
                    if(key != 'company' && key != 'read' && key != 'date')
                        resolve(false);
            }
            resolve(true)
        }).then((res) => {
            console.log(res);
            if(res){
                send();
            }else{
                Swal.fire({
                    icon:'error',
                    title:'שגיאה',
                    text: 'שים לב להערות שגיאה ומלא את שדות החובה בבקשה.'
                })
            }
        })
    }

    const send = async () => {
        console.log('im fine!');
        await projectFirestore.collection('Contact').doc().set(form.value);
        Swal.fire({
            icon: 'success',
            title: "נשלח בהצלחה!"
        }).then(() => {
            router.push('/')
        })
    }

    onMounted(() => {
        const contact = document.getElementById('contact');
        if(contact){
            contact.classList.toggle('fade-in')
        }
    })
    
    return{
        form,send, form_error, check
    }
}
}
</script>

<style scoped>
.conatct{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 420px;
    overflow-y: auto;
    color: whitesmoke;
}
::v-deep(.el-input__inner){
    height: 60px;
    text-align:center;
    margin-bottom: 10px;
    font-size: 24px;
}
.option-btn{
    width: 100%;
    margin-top: 15px;
    height: 60px;
    font-size: 24px;
}
.slogan{
    width: 100%;
    font-size: 20px;
    letter-spacing: 0.5px;
    color: var(--bg-white);
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 15px;
}
.logo{
    width: 100%;
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 500;
    color: var(--bg-white);
}
.error{
    color: var(--bg-red);
    font-weight: 500;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1004px) {
    .conatct{
        display: block;
        padding: 25px;
    }
    .slogan{
        font-size: 16px;
    }
    .logo{
        font-size: 40px;
    }
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>